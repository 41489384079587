import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LemonadeLP from "../../images/lemonade.png"
import EasterEgg from "../../images/lemonade-2.jpeg"
import Envs from "../../images/lemonade-envs.png"
import Pool from "../../images/swimming-pool-liability.jpg"
import LP4 from "../../images/lemonade-goes-nationwide.mp4"
import API from "../../images/lemonade-api.mp4"
import Query from "../../images/query-alerter.png"

const Lemonade = () => (
  <Layout>
    <SEO title="what the tech" />
    <div className="c12 title main">
        <h1>Lemonade</h1>
    </div>
    <div className="c12 main">
        <div className="summary">
            <div className="summary-section">
                <div className="section summary-item">
                    <h5 className="subtitle">Overview</h5>
                    <p>
                        Below are some samples of my work, that I'm able to disclose, as a software engineer at Lemonade. Lemonade is an insurtech startup selling renters and homeowners insurance in the US and Europe, as well as pet and life insurance in the US.
                    </p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Project Type</h5>
                    <p>Work</p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Timeline</h5>
                    <p>2017-2020</p>
                </div>
            </div>
            <img src={LemonadeLP} className="summary-section" />
        </div>
    </div>
    <div className="grid">
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">2017-2018</h5>
                <h2 className="lemonade-title">Frontend Work</h2>
                <p>Majority of my work was done on our internal backoffice system, but here are some projects I worked on for our customer-facing product that I can share:</p>
                <br/>
                <p>Tools: React.js, Redux</p>
                <br/>
                <video autoPlay loop muted playsInline src={LP4} className="summary-section"></video>
                <br/>
                <p>"Lemonade Goes Global" landing page</p>
                <br/>
                <p>UI design: <a className="lemonade-link" target="_blank" href="https://www.instagram.com/thegalshir/">Gal Shir</a>, Frontend: Eden Adler</p>
            </div>
            <div className="section">
                <video autoPlay loop muted playsInline src={API} className="summary-section"></video>
                <br/>
                <p>"Lemonade API" landing page</p>
                <br/>
                <p>UI design: <a className="lemonade-link" target="_blank" href="https://www.instagram.com/thegalshir/">Gal Shir</a>, Frontend: Ofer Vugman, Eden Adler</p>
            </div>
            <div className="section">
                <img src={EasterEgg} className="summary-section" />
                <br/>
                <p>Easter Egg</p>
                <br/>
                <p>As a "secret project" I created ASCII art out of our Lemonade logo and put it as a comment in our code, so that if a developer inspects and opens the developer console in the browser, they'd see this message:</p>
            </div>
        </div>
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">2018-2019</h5>
                <h2 className="lemonade-title">DevOps Work</h2>
                <p>As a DevOps engineer I helped setup our first data lake, warehouse, and pipeline. I also automated and scaled our infrastructure. Here's an example of a project I worked on:</p>
                <br/>
                <p>Tools: AWS, Docker, Kubernetes, Node.js, Ruby on Rails, Slack bot, Terraform, Snowflake</p>
                <br/>
                <img src={Envs} className="summary-section" />
                <p>Dynamically Scaling Environments</p>
                <br/>
                <p>When the engineering team was small for the first few years, we had a finite number of "staging" or test environments for us to test out our features before they go into production. As the team grew, it became increasingly hard and expensive to manage all of these static environments (sometimes not all were in use, other times we didn't have enough).</p>
                <br/>
                <p>As a DevOps engineer, I set up our Slack bot and infrastructure to support the dynamic scaling of environments. Whenever a feature was ready for testing, the Slack bot would automatically spin up an environment. The environment was labeled to match the ticket number of the feature so that the engineer, product, designers, and quality assurance would all be aligned on how to access and test the feature. As a result, instead of having only 13 available environments, we are now able to support an infinite number of testing environments.</p>
            </div>
        </div>
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">2019-2020</h5>
                <h2 className="lemonade-title">Backend Work</h2>
                <p>As a Backend engineer I worked on our API used by many business development partners, added important features and functionality to our U.S. homeowners and renters product, and supported our efforts towards breaking apart our monolith architecture. Here's a few examples of my work:</p>
                <br/>
                <p>Tools: Ruby on Rails, Slack bot, Docker</p>
                <br/>
                <img src={Pool} className="summary-section" />
                <p>Add-ons</p>
                <br/>
                <p>As a backend engineer, I implemented the logic of the swimming pool liability add-on for policies. This was a complex feature since, behind-the-scenes, our policies are built with a swimming pool exclusion, so I had to implement all of the logic for this feature backward (when the user adds swimming pool coverage, in the backend I had to program this as removing the exclusion).</p>
            </div>
            <div className="section">
                <img src={Query} className="summary-section" />
                <br/>
                <p>Unindexed query alerter</p>
                <br/>
                <p>We encountered several instances where engineers deployed features that included unindexed queries, which hogged our databases' resources and caused downtime for Lemonade's web and mobile app. Sometimes these inefficient queries would be discovered and fixed during the code review stage, but sometimes they slipped through the cracks.</p>
                <br/>
                <p>After accidentally causing a short downtime because of this issue, I decided to find a way to catch these issues before they reach production. I built a script that runs alongside our pre-deploy testing phase, scans through the queries made to the database, and determines if any new problematic queries were detected. If found, it would comment on the exact place in the code for the developer to identify and fix. The next phase will be to make this alter system block features from being deployed if an issue is encountered.</p>
            </div>
        </div>
    </div>
  </Layout>
)

export default Lemonade